<template>
  <carousel
    v-if="news && news.length"
    :items="1"
    :autoplay="true"
    :autoplayTimeout="3500"
    :nav="false"
    class="homepage-slider"
  >
    <div
      class="slider-item"
      :style="`background: url('${
        topic.better_featured_image
          ? topic.better_featured_image.source_url
          : ''
      }') center;background-size:cover;`"
      :key="topic.id"
      v-for="topic in news"
    >
      <div class="container">
        <h2 v-html="topic.title.rendered"></h2>
        <div class="mb-5 post-content" v-html="topic.excerpt.rendered"></div>
        <router-link
          style="position: relative; z-index: 10"
          :to="`/topic/${topic.slug}`"
          class="btn btn-outline-white"
          >Czytaj więcej <i class="fas fa-chevron-right ml-2"></i
        ></router-link>
      </div>
    </div>
  </carousel>
</template>

<script>
import carousel from "vue-owl-carousel2";
import _ from "lodash";
export default {
  components: { carousel },
  name: "HomePageSlider",
  data() {
    return {
      news: [],
    };
  },
  mounted() {
    this.getNews();
  },
  computed: {
    user() {
      return this.$store.getters.userData;
    },
    config() {
      return this.$store.getters.config;
    },
    slug() {
      return this.$route.params.slug;
    },
  },
  methods: {
    getNews() {
      this.$http
        .get(
          `wp/v2/posts?per_page=6&_embed&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`,
          this.config ? this.config : null
        )
        .then((response) => {
          this.loading = false;
          this.news = _.filter(response.data, (news) => {
            if (
              !news.slug.includes("video-tutorial") &&
              !news.categories.includes(1748)
            ) {
              return news;
            }
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.homepage-slider {
  margin-bottom: 100px;
  .post-content {
    position: relative;
    z-index: 10;
  }
  .slider-item {
    max-height: 156px;
    min-height: 436px;
    padding: 90px 0;
    color: white;
    position: relative;
    &:after {
      content: "";
      background: rgba(46, 58, 89, 0.5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;
      position: absolute;
    }
    h2 {
      font-weight: 600;
      margin-bottom: 32px;
      font-size: clamp(24px, 1.875vw, 36px);
      position: relative;
      z-index: 10;
    }
    p {
      font-size: clamp(16px, 1.25vw, 24px);
      font-weight: 400;
      margin-bottom: 64px;
      position: relative;
      z-index: 10;
    }
  }
}
</style>
