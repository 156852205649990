import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Vuex from "vuex";
import App from "./App.vue";
import dayjs from "dayjs";
import "dayjs/locale/pl";

import("./assets/scss/main.scss");
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(Vuex);

import axios from "axios";
axios.defaults.baseURL = "https://old-support.hrsys.pl/wp-json/";
Vue.prototype.$http = axios;

import "./registerServiceWorker";
import router from "./router";

Vue.config.productionTip = false;

dayjs.locale("pl");

Vue.filter("formatDate", function (date) {
  return dayjs(date).format("DD MMMM YYYY");
});
Vue.filter("striphtml", function (value) {
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});
Vue.filter("toFixed", function (value, howMuch) {
  return parseFloat(value).toFixed(howMuch);
});
Vue.filter("truncate", function (value, howMuch) {
  return value.length > howMuch
    ? value.substr(0, howMuch - 1) + "&hellip;"
    : value;
});

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

const store = new Vuex.Store({
  state: {
    user: null,
    userData: null,
    config: null,
  },
  mutations: {
    updateUser(state, data) {
      state.user = data;
      localStorage.setItem("user", JSON.stringify(data));
      state.config = {
        headers: { Authorization: `Bearer ${data.token}` },
      };

      axios
        .get(
          "wp/v2/users/me?context=edit&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ",
          state.config
        )
        .then((response) => {
          state.userData = response.data;
        })
        .catch(() => {
          localStorage.removeItem("user");
          state.userData = null;
          state.user = null;
          state.config = null;
        });
    },
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    userData: (state) => {
      return state.userData;
    },
    config: (state) => {
      return state.config;
    },
  },
  actions: {
    updateUser(context, data) {
      context.commit("updateUser", data);
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  data() {
    return {
      loading: true,
    };
  },
  beforeRouteUpdate() {
    this.loading = true;
  },
  watch: {
    route() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  mounted() {
    if (localStorage.getItem("user")) {
      this.$store.commit(
        "updateUser",
        JSON.parse(localStorage.getItem("user"))
      );
    }
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  computed: {
    route() {
      return this.$route.name;
    },
  },
}).$mount("#app");
