<template>
  <div>
    <div id="header">
      <div class="container">
        <h1>Witaj w Centrum Rozwoju</h1>
        <h3>Cieszymy się, że jesteś z nami!</h3>
        <SearchBar />
      </div>
      <HomePageContainers />
    </div>
    <HomePageSlider />
    <HomePageNews />
  </div>
</template>

<script>
// @ is an alias to /src
import SearchBar from "../components/SearchBar";
import HomePageContainers from "../components/HomePageContainers";
import HomePageSlider from "../components/HomePageSlider";
import HomePageNews from "../components/HomePageNews";
export default {
  name: "Home",
  components: { HomePageNews, HomePageSlider, HomePageContainers, SearchBar },
};
</script>
