<template>
  <div class="topic-container" v-if="topic.show" :key="topic.id">
    <div
      class="topic-image"
      v-if="topic.better_featured_image"
      :style="`background-image:url(${topic.better_featured_image.source_url})`"
    ></div>
    <div class="topic-content">
      <span> {{ topic.date }}, {{ topic._embedded.author[0].name }} </span>
      <h5 class="topic-title-content" v-html="topic.title.rendered"></h5>
      <div
        class="topic-description line-clamp"
        v-html="topic.excerpt.rendered"
      ></div>
      <div class="d-flex justify-content-end">
        <router-link :to="`/topic/${topic.slug}`" class="read-more-btn mb-2"
          >Czytaj więcej <i class="fas fa-chevron-right ml-2"></i
        ></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topic: {
      type: Object,
      required: true,
    },
  },
  name: "TopicContainer",
};
</script>

<style scoped>
.topic-title-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
