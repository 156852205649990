import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Admin.vue"),
  },
  {
    path: "/download",
    name: "Download",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Download.vue"),
  },
  {
    path: "/documentation",
    name: "Documentation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Documentation.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import(/* webpackChunkName: "about" */ "../views/Faq.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  // {
  //   path: "/login-admin",
  //   name: "login-admin",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  // },
  {
    path: "/knowledge-base",
    name: "knowledgeBase",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KnowledgeBase.vue"),
  },
  {
    path: "/knowledge-base/guide",
    name: "knowledgeBaseGuide",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KnowledgeBaseGuide.vue"),
  },
  {
    path: "/knowledge-base/tutorials",
    name: "knowledgeBaseTutorials",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/KnowledgeBaseTutorials.vue"
      ),
  },
  {
    path: "/knowledge-base/hr-guide",
    name: "knowledgeBaseHRGuide",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/KnowledgeBaseHRGuide.vue"
      ),
  },
  {
    path: "/knowledge-base/webinars",
    name: "knowledgeBaseWebinars",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/KnowledgeBaseWebinars.vue"
      ),
  },
  {
    path: "/search-results/:search",
    name: "SearchResults",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SearchResults.vue"),
  },
  {
    path: "/course-book/:type",
    name: "CourseBook",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Coursebook.vue"),
  },
  {
    path: "/topic/:slug",
    name: "Topic",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Topic.vue"),
  },
  {
    path: "/documentation/post/:id",
    name: "DocumentationPost",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DocumentationPost.vue"),
  },
  {
    path: "/news/:categoryId",
    name: "News",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/News.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  mounted() {
    console.log("!!!!!");
  },
});

export default router;
